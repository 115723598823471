export function name(name) {
    return name.trim() !== '' ? true : false
}

export function email(email) {
    if (email === null || email === "") {
        return false
    }
    else if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        if (email.slice(-4) === '.con') {
            return false
        }
        return true
    }
    else {
        return false
    }
};

export function address(address) {
    return address.trim() !== '' ? true : false
}

export function city(city) {
    return city.trim() !== '' ? true : false
}

export function postalCode(postalCode, region) {
    if (region === "CA") {
        if (postalCode.match(/([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i)) {
            return true
        }
    }
    else if (region === "US") {
        if (postalCode.match("^\\d{5}(-{0,1}\\d{4})?$")) {
            return true
        }
    }
    else if (region === 'GB') {
        if (postalCode.length >= 6 && postalCode.length <= 8) {
            return true
        }
    }
    else if (region === 'AU') {
        if (postalCode.length === 4) {
            return true
        }
    }
    return false
}

export function select(select) {
    return select !== '';
}

export function phone(phone) {
    return (phone.length === 10 || phone.length === 11);
}