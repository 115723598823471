import React, { useState, useEffect } from 'react';
import { styled, Box, Button, CircularProgress, Typography} from '@material-ui/core';
import CustomField from './CustomField';
import { getRegistrationQuestions } from '../utility/requests';
import dayjs from 'dayjs';
import { useNotification } from 'utility/notification';
import { t, Trans } from '@lingui/macro';



export default function Registration(props) {
    const { eventId, onSubmit, onCancel } = props;

    const [state, setState] = useState({
        status: 'loading',
        questions: null,
        defaultValues: null
    });

    useEffect(() => {
        getRegistrationQuestions(eventId)
            .then(data => {
                const sessionData = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_REGISTRATION_KEY));
                let defaultValues = null;

                if (sessionData) {
                    if (sessionData.eventId === eventId && (Date.now() - sessionData.lastUpdated) < (1 * 60 * 60 * 1000)) {
                        // Restore user registration session
                        defaultValues = sessionData.form;
                    } else {
                        // Clear invalid registration form data
                        sessionStorage.removeItem(process.env.REACT_APP_REGISTRATION_KEY);
                    }
                }

                setState({
                    status: 'success',
                    questions: data,
                    defaultValues: defaultValues
                })
            })
            .catch(() => null)
    }, [eventId]);


    if (state.status === 'loading') {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height='100%' width='100%'>
                <CircularProgress />
            </Box>
        )
    }

    if (state.status === 'error') {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height='100%' width='100%'>
                <Typography>Unable to open checkout</Typography>
            </Box>
        )
    }

    return (
        <RegistrationForm
            eventId={eventId}
            questions={state.questions}
            defaultValues={state.defaultValues}
            onSubmit={(values) => onSubmit(values)}
            onCancel={onCancel}
        />
    )
}


function RegistrationForm(props) {
    const { eventId, questions, defaultValues, onSubmit, onCancel } = props;

    const [form, setForm] = useState(() => initializeForm(questions, defaultValues));

    const { createNotification } = useNotification();


    // ***** Process Form Value Changes ***** //

    const handleChange = (index, value, optionId) => {
        const newForm = Array.from(form);

        switch (form[index].type) {
            case FieldTypes.AGE:
                newForm[index].value = value.replace(/[^0-9]/g,'').slice(0,3);
                break;

            case FieldTypes.MULTIPLE_CHOICE:
                value === true
                    ? newForm[index].value.add(optionId)
                    : newForm[index].value.delete(optionId)
                break;

            default:
                newForm[index].value = value;
                break;
        }

        newForm[index].errorMsg = null;

        setForm(newForm);

        // Backup current values to session storage
        sessionStorage.setItem(process.env.REACT_APP_REGISTRATION_KEY, JSON.stringify({
            eventId: eventId,
            lastUpdated: Date.now(),
            form: newForm.map(f => {
                if (f.type === FieldTypes.MULTIPLE_CHOICE) {
                    return { id: f.id, value: Array.from(f.value) }
                } else {
                    return { id: f.id, value: f.value }
                }
            })
        }))
    };



    // ***** Validate Form Values ***** //

    const handleValidation = () => {

        let formError = false;
        let formValues = Array.from(form);

        // Check for missing required fields
        for (const field of formValues) {
            const { error, errorMsg } = fieldValidator(field.value, field.type, field.required);

            if (error) {
                formError = true;
                field.errorMsg = errorMsg;
            }
        }
        
        if (formError === false) {
            submitForm();
        } else {
            setForm(formValues);
            createNotification('Please ensure the form is filled in correctly', { variant: 'warning' })
        }
    };



    // ***** Format and Submit Values ***** //

    const submitForm = () => {

        // Format data
        const data = form.reduce((accumulator, field) => {

            let value = null;

            switch (field.type) {

                case FieldTypes.SHORT_ANSWER:
                case FieldTypes.PARAGRAPH:
                    if (field.value.trim()) {
                        value = field.value.trim();
                    }
                    break;

                case FieldTypes.SINGLE_CHOICE:
                case FieldTypes.DROPDOWN:
                    if (field.value) {
                        value = field.value;
                    }
                    break;

                case FieldTypes.AGE:
                    if (field.value) {
                        value = Number(field.value);
                    }
                    break;

                case FieldTypes.FULL_DATE:
                    if (field.value) {
                        value = dayjs(field.value).format('YYYY-MM-DD');
                    }
                    break;

                case FieldTypes.SHORT_DATE:
                    if (field.value) {
                        value = dayjs(field.value).format('YYYY-MM-01'); // Only set the year + month. Day is always 01
                    }
                    break;

                case FieldTypes.MULTIPLE_CHOICE:
                    if (field.value.size) {
                        value = Array.from(field.value);
                    }
                    break;

                case FieldTypes.YES_OR_NO:
                    if (typeof field.value === 'boolean') {
                        value = field.value;
                    }
                    break;

                default:
                    break;
            }

            if (value !== null) {
                accumulator.push({
                    id: field.id,
                    datatype: field.datatype,
                    value: value
                })
            }

            return accumulator;

        }, []);

        onSubmit(data)
    };


    return (
        <Root>
            <Content>

                {form.map((field, index) => (

                    <CustomField
                        key={index}
                        index={index}
                        onChange={handleChange}
                        {...field}
                    />

                ))}

                <Box display='flex' justifyContent='flex-end'>
                    <CancelButton onClick={onCancel}><Trans>Cancel</Trans></CancelButton>
                    <SubmitButton color='primary' onClick={handleValidation}><Trans>Continue</Trans></SubmitButton>
                </Box>
                
            </Content>
        </Root>
    )
}


// Format registration questions to be used as form state
function initializeForm(data, defaultValues) {
    const form = Array.from(data);

    for (const field of form) {
        field.errorMsg = null;

        switch (field.type) {
            case FieldTypes.MULTIPLE_CHOICE:
                field.value = new Set();
                break;

            case FieldTypes.SHORT_ANSWER:
            case FieldTypes.PARAGRAPH:
            case FieldTypes.AGE:
            case FieldTypes.DROPDOWN:
                field.value = '';
                break;

            default:
                field.value = null;
                break;
        }


        // Restore values from user session if available
        // Note: Text Blocks are read only, and don't have a value associated with them
        if (defaultValues && field.type !== FieldTypes.TEXT_BLOCK && field.type !== FieldTypes.IMAGE_BLOCK) {
            const d = defaultValues.filter(v => v.id === field.id);

            if (d && d.length === 1) {
                if (Array.isArray(d[0].value)) {
                    field.value = new Set(d[0].value)
                } else {
                    field.value = d[0].value;
                }
            }
        }
    }

    return form;
}


function fieldValidator(value, type, required) {
    switch (type) {

        case FieldTypes.SHORT_ANSWER:
        case FieldTypes.PARAGRAPH:
            if (required && value.trim().length === 0) {
                return { error: true, errorMsg: t`This field is required`}
            } else if (value.trim().length > 2000) {
                return { error: true, errorMsg: t`This field cannot contain more than 2000 characters` }
            } else {
                return { error: false, errorMsg: null }
            }

        case FieldTypes.SINGLE_CHOICE: // null if none selected
        case FieldTypes.DROPDOWN: // empty string if none selected
            if (required && !value) {
                return { error: true, errorMsg: t`This field is required`}
            } else {
                return { error: false, errorMsg: null }
            }

        case FieldTypes.MULTIPLE_CHOICE:
            if (required && value.size === 0) {
                return { error: true, errorMsg: t`This field is required` }
            } else {
                return { error: false, errorMsg: null }
            }
        
        case FieldTypes.FULL_DATE:
        case FieldTypes.SHORT_DATE:
            if (required && !value) {
                return { error: true, errorMsg: t`This field is required` }
            }
            if (!required && !value) {
                return { error: false, errorMsg: null }
            }
            else if (dayjs(value).isValid() === false) {
                return { error: true, errorMsg: t`Please enter a valid date` }
            }
            else {
                return { error: false, errorMsg: null }
            }
        
        case FieldTypes.AGE:

            if (isNaN(value)) {
                return { error: true, errorMsg: t`Please enter a valid number` }
            }
            if (required && !value) {
                return { error: true, errorMsg: t`This field is required` }
            }
            if (!required && !value) {
                return { error: false, errorMsg: null }
            }
            if (Number(value) < 1 || Number(value > 120)) {
                return { error: true, errorMsg: t`Please enter a valid number` }
            }
            return { error: false, errorMsg: null }
        
        case FieldTypes.YES_OR_NO:
            if (required && typeof value !== 'boolean') {
                return { error: true, errorMsg: t`This field is required` }
            } else {
                return { error: false, errorMsg: null }
            }

        case FieldTypes.TEXT_BLOCK:
        case FieldTypes.IMAGE_BLOCK:
            return { error: false, errorMsg: null }

        default:
            break;
    }
}


const FieldTypes = {
    'SHORT_ANSWER': 1,
    'PARAGRAPH': 2,
    'SINGLE_CHOICE': 3,
    'MULTIPLE_CHOICE': 4,
    'DROPDOWN': 5,
    'FULL_DATE': 6,
    'AGE': 7,
    'YES_OR_NO': 8,
    'SHORT_DATE': 9,
    'TEXT_BLOCK': 999,
    'IMAGE_BLOCK': 998
}


const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    backgroundColor: '#f0f0f0',
    padding: theme.spacing(2),
    paddingBottom: 270
}));

const Content = styled('div')(({ theme }) => ({
    width: theme.breakpoints.values.md,
    marginRight: 'auto',
    marginLeft: 'auto'
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(2)
}));
SubmitButton.defaultProps = { variant: 'contained' };

const CancelButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.warning.dark,
    color: theme.palette.warning.dark
}));
CancelButton.defaultProps = { variant: 'outlined' };